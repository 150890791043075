import React from 'react';
import './Cards.css';
import CardItem from './CardItem';
import qt0 from '../assets/images/QT0.png'
import qt1 from '../assets/images/wow.jpeg'
import qt2 from '../assets/images/logo.png'
import qt3 from '../assets/images/logo.png'
import qt4 from '../assets/images/logo.png'


import { useTranslation } from "react-i18next";

function Cards() {

  const { t } = useTranslation();

  return (
    <div className='cards'>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src={qt0}
              text={t('text.cardText1')}
              label={t('text.adventure')}
              path='https://www.questown.cz'
            />
            <CardItem
              src={qt1}
              text={t('text.cardText2')}
              label={t('text.pointOfInterest')}
              path='/about'
            />
          </ul>

        </div>
      </div>
    </div>
  );
}

export default Cards;
