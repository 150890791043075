import React from 'react';
import './Footer.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import logo from '../assets/images/logo.png';
import { useTranslation } from "react-i18next";

function Footer() {

  const { t } = useTranslation();

  return (
    /**
     *    <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
          Join the Adventure newsletter to receive our best vacation deals
        </p>
        <p className='footer-subscription-text'>
          You can unsubscribe at any time.
        </p>
        <div className='input-areas'>
          <form>
            <input
              className='footer-input'
              name='email'
              type='email'
              placeholder='Your Email'
            />
            <Button buttonStyle='btn--outline'>Subscribe</Button>
          </form>
        </div>
      </section> 
     */
    <div className='footer-container'>

      <div className='footer-links'>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>{t('title.about')}</h2>
            <Link to='/'>{t('text.termsOfServ')}</Link>
          </div>
          <div className='footer-link-items'>
            <h2>{t('title.contact')}</h2>
            <Link to='/contact'>{t('title.contact')}</Link>
            <Link to='/sponsorship'>{t('text.sponsorship')}</Link>
          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>{t('text.socials')}</h2>
            <Link target='_blank' to='https://www.facebook.com/people/Questown/61558509303549'>Facebook</Link>
            <Link target='_blank' to='https://twitter.com/questownGame'>X Twitter</Link>
            <Link target='_blank' to='https://www.linkedin.com/company/midnight-tree-s-r-o'>LinkedIn</Link>
          </div>
        </div>
      </div>
      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-logo'>
            <Link to='/' className='social-logo'>
              MIDNIGHT TREE
              &nbsp;
              <img width="50px" src={logo} />
            </Link>
          </div>
          <small className='website-rights'>MIDNIGHT TREE s.r.o © 2024</small>
          <div className='social-icons'>
            <Link
              className='social-icon-link facebook'
              to='https://www.facebook.com/profile.php?id=61563037607507'
              target='_blank'
              aria-label='Facebook'
            >
              <i className='fab fa-facebook-f' />
            </Link>
            <Link
              className='social-icon-link instagram'
              to='https://www.instagram.com/questownGame'
              target='_blank'
              aria-label='Instagram'
            >
              <i className='fab fa-instagram' />
            </Link>
            <Link
              className='social-icon-link twitter'
              to='https://twitter.com/questownGame'
              target='_blank'
              aria-label='Twitter'
            >
              <i className='fab fa-x-twitter' />
            </Link>
            <Link
              className='social-icon-link twitter'
              to='https://www.linkedin.com/company/midnight-tree-s-r-o'
              target='_blank'
              aria-label='LinkedIn'
            >
              <i className='fab fa-linkedin' />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
